import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import refresh from './slices/refreshSlice'
import userInfo from './slices/userInfoSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        refresh,
        userInfo,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
