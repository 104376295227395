import { createSlice } from "@reduxjs/toolkit";

const refreshSlice = createSlice({
    name: 'refresh',
    initialState: {
        refreshStatus: false,
    },
    reducers: {
        refetchHandler: (state,action) => {
            state.refreshStatus = action.payload
        }
    }
});

export const {refetchHandler} = refreshSlice.actions;
export default refreshSlice.reducer;