import { createSlice } from "@reduxjs/toolkit";

const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState: {
        userInfo: null,
    },
    reducers: {
        setUserInfoHandler: (state,action) => {
            state.userInfo = action.payload
        }
    }
});

export const {setUserInfoHandler} = userInfoSlice.actions;
export default userInfoSlice.reducer;